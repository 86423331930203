'use strict';

/**
 *  Load modules
 */
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import '../scss/app.scss';
var browserWidth = window.innerWidth || document.documentElement.clientWidth,
  hideShowMenuButton = document.querySelector('.hide-show-menu'),
  header = document.querySelector('.header'),
  firstClickHook = 1;
hideShowMenuButton.onclick = function () {
  if (firstClickHook > 2) {
    header.classList.toggle('header--menu-open');
  } else {
    firstClickHook++;
    header.classList.add('header--menu-open');
  }
};

// use passive listeners to improve scrolling performance
var e,
  t = ["scroll", "wheel", "touchstart", "touchmove", "touchenter", "touchend", "touchleave", "mouseout", "mouseleave", "mouseup", "mousedown", "mousemove", "mouseenter", "mousewheel", "mouseover"];
if (function () {
  var e = !1;
  try {
    var t = Object.defineProperty({}, "passive", {
      get: function get() {
        e = !0;
      }
    });
    window.addEventListener("test", null, t), window.removeEventListener("test", null, t);
  } catch (e) {}
  return e;
}()) {
  var o = EventTarget.prototype.addEventListener;
  e = o, EventTarget.prototype.addEventListener = function (o, r, n) {
    var s,
      a = "object" == _typeof(n) && null !== n,
      i = a ? n.capture : n;
    (n = a ? function (e) {
      var t = Object.getOwnPropertyDescriptor(e, "passive");
      return t && !0 !== t.writable && void 0 === t.set ? Object.assign({}, e) : e;
    }(n) : {}).passive = void 0 !== (s = n.passive) ? s : -1 !== t.indexOf(o) && !0, n.capture = void 0 !== i && i, e.call(this, o, r, n);
  }, EventTarget.prototype.addEventListener._original = e;
}
document.addEventListener('touchstart', function () {}, {
  passive: false
});